import { useContext, useEffect, useState } from "react";
import EvidencesHeader from "./EvidencesHeader";
import InitialEvidenceScreen from "./InitialEvidenceScreen";
import FilteredSortedEvidence from "./FilteredSortedEvidence";
import EvidenceResults from "./EvidenceResults";
import { Box, Popover, Group, Button, ActionIcon, Flex, Modal, Stack, Text, Anchor, Loader } from "@mantine/core";
import IdeaContext from "../IdeaContext";
import Inbox from "./Inbox";
import { IconCheck, IconX } from "@tabler/icons";
import { RefreshCheckIcon, AssignNewideaIcon } from '../../../icons/x-symbol-svgrepo-com';
import { RequestAssignment } from "../right-bar/evidence-card/actions/RequestAssignment";
import { showNotification } from "@mantine/notifications";
import { assignEvidencesToNewIdea } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../context/SyncContext";
import { keyframes } from "@emotion/react";

interface BodyProps {
  chooseMatch: any; // Consider specifying more detailed types if possible
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  activeSortOrFilter: boolean;
  displayedRequests: any[]; // Consider specifying what type of objects are in the array
  // sortEvidence: any;
  // activeSortValue: string;
  activeSearch: boolean;
  searchEvidence: any;
  searchedEvidence: any[]; // Consider specifying what type of objects are in the array
  query: string;
  textItemsResults: any[]; // Consider specifying what type of objects are in the array
  loadingtextItems: boolean;
  sortDescending: boolean;
  chosenEvidence: any; // Consider specifying the type if known
  reviewMode: any;
  navToPerspective: any;
  setActiveSearch: any;
  allRequests: any;
  displayedMatches: any;
}

const Body: React.FC<BodyProps> = ({
  chooseMatch,
  addEvidenceFormScreen,
  setAddEvidenceFormScreen,
  setCardView,
  activeSortOrFilter,
  displayedRequests,
  activeSearch,
  searchEvidence,
  searchedEvidence,
  query,
  textItemsResults,
  loadingtextItems,
  sortDescending,
  chosenEvidence,
  reviewMode,
  navToPerspective,
  setActiveSearch,
  allRequests,
  displayedMatches
}) => {

  const {
    activeInboxTab,
    idea,
    loadEvidence
  } = useContext(IdeaContext);

  const [showMatchesResults, setShowMatchesResults] = useState(true);
  const [showRequestsResults, setShowRequestsResults] = useState(true);
  const [showQueryResults, setShowQueryResults] = useState(true);
  const [selectedEvidenceIds, setSelectedEvidenceIds] = useState<string[]>([]);
  const [opened, setOpened] = useState(false);
  const [isValidationDone, setIsValidationDone] = useState<boolean | null>(null);
  const auth0 = useAuth0();
  const [componentId, setComponentId] = useState();
  const { defaultPriority, defaultStatus } = useContext(SyncContext);
  useEffect(()=>{
    setComponentId(idea?.componentId)
  },[idea])

  const slideUp = keyframes`
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  // Define fade-out animation
  const slideDown = keyframes`
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  `;

  const validateComponentId = (componentId: string | undefined) => {
    if (!componentId) {
      showNotification({
        title: "Product Area Missing",
        color: "red",
        message:
          "Please assign a product area to this idea before creating a new idea.",
        icon: <IconX size="1.1rem" />,
        autoClose: 1200,
      });
      return false;
    }
    return true;
  };
  
  const createIdeaObject = () => {
    return {
      idea: {
        title: "< Untitled Idea >",
        description: "< Description >",
        componentId,
        ownerId: idea.ownerId,
        rolloutDate: null,
        status: defaultStatus,
        priority: defaultPriority,
      },
      evidences: selectedEvidenceIds,
    };
  };
  
  const submitNewIdea = (body: any, auth0: any) => {
    assignEvidencesToNewIdea(body, auth0)
      .then((response) => {
        const ideaId = response.createNewIdeaRes?.idea?._id;
        setSelectedEvidenceIds([]);
        loadEvidence();
        showNotification({
          title: "Assigned Successfully!",
          color: "teal",
          message: (
            <Box>
              <Text>New idea has been created.</Text>
              {ideaId && (
                <Anchor href={`/idea/${ideaId}`} target="_blank">
                  Go to the new idea
                </Anchor>
              )}
            </Box>
          ),
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200,
        });

        if (ideaId) {
          setTimeout(() => {
            window.open(`/idea/${ideaId}`);
          }, 1300);
        } else {
          console.log("Did not receive an idea ID for the new idea", response);
        }
      })
      .catch((error) => {
        console.error("Error creating idea:", error);
        showNotification({
          title: "Error Creating Idea",
          color: "red",
          message:
            "An error occurred while creating the idea. Please try again.",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200,
        });
      });
  };
  
  const createNewIdea = () => {
    if (!validateComponentId(componentId)) return;
  
    const newIdea = createIdeaObject();
    submitNewIdea(newIdea, auth0);
  };
  
  return (
    <Box style={{position: "relative"}}>
      {opened && (
        <Modal
          mt={100}
          opened={opened}
          onClose={() => setOpened(false)}
          size="32vw"
          title={
            <Text fz={20} fw={600}>
              Assign to another idea
            </Text>
          }
          styles={{
            content: {
              left: "50%",
              transform: "translateX(-50%)", 
            },
          }}
        >
          <Stack h={445} pt={5} justify="center" w="100%">
            <Stack justify="center">
              <RequestAssignment
                idea={idea}
                item={chosenEvidence}
                setOpened={setOpened}
                navToPerspective={navToPerspective}
                TextInputW="100%"
                multiSelect={true}
                selectedEvidenceIds={selectedEvidenceIds}
                setSelectedEvidenceIds={setSelectedEvidenceIds}
              />
            </Stack>
          </Stack>
        </Modal>
      )}
      <Box /* h='80px' */>
        <EvidencesHeader
          addEvidenceFormScreen={addEvidenceFormScreen}
          setAddEvidenceFormScreen={setAddEvidenceFormScreen}
          setCardView={setCardView}
          searchEvidence={searchEvidence}
          activeSearch={activeSearch}
          searchedEvidence={searchedEvidence}
          showMatchesResults={showMatchesResults}
          setShowMatchesResults={setShowMatchesResults}
          showRequestsResults={showRequestsResults}
          setShowRequestsResults={setShowRequestsResults}
          showQueryResults={showQueryResults}
          setShowQueryResults={setShowQueryResults}
          textItemsResultsLength={textItemsResults?.length || 0}
          query={query}
          sortDescending={sortDescending}
          reviewMode={reviewMode}
          navToPerspective={navToPerspective}
          setActiveSearch={setActiveSearch}
          allRequests={allRequests}
          displayedMatches={displayedMatches}
          setIsValidationDone={setIsValidationDone}
          isValidationDone={isValidationDone}
        />

      </Box>
      
   
      <Box pt="sm" 
        h={!isValidationDone ? "calc(100vh - 323px)" : "calc(100vh - 256px)"} 
        mah={!isValidationDone ? "calc(100vh - 323px)" : "calc(100vh - 256px)"} 
        sx={{
          overflowY:"scroll"
        }}>
        
        {activeSearch ? (
          <EvidenceResults
            displayedRequests={searchedEvidence}
            chooseMatch={chooseMatch}
            showMatchesResults={showMatchesResults}
            showRequestsResults={showRequestsResults}
            showQueryResults={showQueryResults}
            textItemsResults={textItemsResults}
            loadingtextItems={loadingtextItems}
            chosenEvidence={chosenEvidence}

          />
        ) : activeSortOrFilter ? (
          <FilteredSortedEvidence
            displayedRequests={displayedRequests}
            chooseMatch={chooseMatch}
            chosenEvidence={chosenEvidence}
          />
        ) : 
          activeInboxTab ?

            <Inbox 
              chooseMatch={chooseMatch}
              selectedEvidenceIds={selectedEvidenceIds}
              setSelectedEvidenceIds={setSelectedEvidenceIds}
            />
            :
            (
              <InitialEvidenceScreen
                chooseMatch={chooseMatch}
                chosenEvidence={chosenEvidence}
                selectedEvidenceIds={selectedEvidenceIds}
                setSelectedEvidenceIds={setSelectedEvidenceIds}
              />
            )}
      </Box>
      <Popover
        opened={selectedEvidenceIds.length > 0}
        position="bottom"
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Popover.Target>
          <div style={{ width: 0, height: 0 }} />
        </Popover.Target>

        <Popover.Dropdown
          sx={{
            minWidth: "95%",
            maxWidth: "100%",
            padding: "10px",
            marginLeft: "22px",
            borderRadius: "8px",
            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
            backgroundColor: "#5C5CEB",
            position: "absolute", // Position relative to Popover
            top: "100%",
            animation: `${selectedEvidenceIds.length > 0 ? slideUp : slideDown} 0.3s ease-out`,
          }}
        >
          <Group position="apart" style={{ width: "100%" }}>
            <span style={{ color: "#ffffff" }}>
              {selectedEvidenceIds.length} evidences selected
            </span>
            <Flex>
              <Button
                size="xs"
                style={{
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  height: "36px",
                  marginRight: "10px",
                }}
                onClick={() => createNewIdea()}
              >
                <AssignNewideaIcon />
                <span style={{ marginLeft: "5px" }}>Assign to a new idea</span>
              </Button>
              <Button
                size="xs"
                style={{
                  color: "#000000",
                  backgroundColor: "#ffffff",
                  height: "36px",
                }}
                onClick={() => setOpened(true)}
              >
                <RefreshCheckIcon />
                <span style={{ marginLeft: "5px" }}>Assign to another idea</span>
              </Button>
              <ActionIcon
                sx={{
                  height: "36px",
                  marginLeft: "10px",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  setSelectedEvidenceIds([]);
                }}
              >
                <IconX color="#ffffff" size={18} />
              </ActionIcon>
            </Flex>
          </Group>
        </Popover.Dropdown>
      </Popover>

    </Box>
  );
};

export default Body