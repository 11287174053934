import { Box, Flex, Group, Stack, Text } from "@mantine/core";
import CardHeader from "./CardHeader";
import { useState } from "react";
import { MentionProvider } from "../../../MentionTextarea/MentionContext";
import CardScroller from "./CardScroller";
import MatchActions from "./actions/MatchActions/MatchActions";
import { IconChevronUp } from "@tabler/icons";

const validationModalSideBar = ({
  navToPerspective,
  setActiveSearch,
  reviewMode,
  ideaPage,
  setValidateModalOpened,
  validationEvidence,
  loading,
  setLoading,
  setideaValidationData,
  setMaturity,
  handlebannerText,
  validationCount,
  setValidationCount,
  setIsValidationDone,
}: {
  navToPerspective: any;
  setActiveSearch: any;
  reviewMode: boolean;
  ideaPage: boolean;
  validateModalOpened: boolean;
  setValidateModalOpened: (opened: boolean) => void;
  validationEvidence: any;
  loading: boolean;
  setLoading: (loading: boolean) => void
  setideaValidationData: any;
  setMaturity: any;
  handlebannerText: any;
  validationCount: number | null;
  setValidationCount: any;
  setIsValidationDone: any;
}) => {
  const [componentId, setComponentId] = useState(
    validationEvidence?.componentObj?._id
  );
 
  return (
    <Box /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} */ h="100%">
      <MentionProvider>
        <Stack
          justify="space-between"
          h="100%" /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} */ /* h='100%' */
        >
          <Box style={{ borderBottom: "1px solid #D8D8DB" }}>
            <Flex
              mt="24px"
              pb="16px"
              px="35px"
              justify="space-between"
              align="center"
              sx={{ borderBottom: "1px solid #D8D8DB" }}
            >
              <CardHeader
                validationCount={validationCount}
                navToPerspective={navToPerspective}
                setValidateModalOpened={setValidateModalOpened}
              />
            </Flex>
            <Group
              position="apart"
              style={{
                borderTop: "1px solid #D8D8DB",
                borderBottom: "1px solid #D8D8DB",
                backgroundColor: "#F8F9FA",
                padding: "12.5px 35px",
              }}
            >
              <Text fw={600} fz={"14px"}>
                Main details
              </Text>
              <IconChevronUp size={16} />
            </Group>
            <CardScroller
              chosenEvidence={validationEvidence}
              reviewMode={reviewMode}
              ideaPage={ideaPage}
              componentId={componentId}
              setComponentId={setComponentId}
              validation={true}
            />
          </Box>
          <Box>
            <MatchActions
              navToPerspective={navToPerspective}
              componentId={componentId}
              chosenEvidence={validationEvidence}
              validation={true}
              setideaValidationData={setideaValidationData}
              setMaturity={setMaturity}
              handlebannerText={handlebannerText}
              setLoading={setLoading}
              setValidationCount={setValidationCount}
              setIsValidationDone={setIsValidationDone}
            />
          </Box>
        </Stack>
      </MentionProvider>
    </Box>
  );
};

export default validationModalSideBar;
