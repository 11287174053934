import {
  Box,
  Flex,
  Modal,
  Text,
  useMantineTheme,
  Button,
  Transition,
  Overlay,
  Loader
} from "@mantine/core";
import { BagelIcon20, BagelIcon40, BagelIcon60, BagelIcon80 } from "../../../icons/x-symbol-svgrepo-com";
import ValidationModalSideBar from "../right-bar/evidence-card/ValidationModalSidebar";
import { IconCircleCheck, IconCheck, IconX } from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { updateValidation } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";

interface ReassignModalProps {
  ideaId: string,
  maturity: number,
  validateModalOpened: boolean;
  setValidateModalOpened: (opened: boolean) => void;
  reviewMode: any;
  navToPerspective: any;
  setActiveSearch: any;
  validationEvidence: any;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setideaValidationData: any;
  setMaturity: any;
  handlebannerText: any;
  setValidationCount: any;
  validationCount: number | null;
  setIsValidationDone: any;
  isValidationDone: boolean | null;
}
function ValidateModal({
  ideaId,
  maturity,
  validateModalOpened,
  setValidateModalOpened,
  reviewMode,
  navToPerspective,
  setActiveSearch,
  validationEvidence,
  loading,
  setLoading,
  setideaValidationData,
  setMaturity,
  handlebannerText,
  setValidationCount,
  validationCount,
  setIsValidationDone,
  isValidationDone,
}: ReassignModalProps) {
  const theme = useMantineTheme();
  let title = "Help Bagel focus on the most relevant evidence "
  let text = "Look through the list of suggested evidence. Assign to Idea relevant evidence or mark as not relevant"
  let CardIcon = BagelIcon20
  if(validationCount == 4){
    title = "You’ve done an amazing job refining the evidence!"
    text = "Your final list of supporting evidence is ready. "
    CardIcon = BagelIcon80
  } else if(validationCount == 3){
    title = "You’ve done an amazing job refining the evidence!"
    text = "Your final list of supporting evidence is ready. "
    CardIcon = BagelIcon60
  } else if(validationCount == 2){
    title = "Every choice you make helps us create a stronger case for your idea!"
    text = "Keep working!"
    CardIcon = BagelIcon40
  }
  const auth0 = useAuth0();

  const finishProcess = () => {
    setValidateModalOpened(false)
    updateValidation(
      auth0, 
      {id: ideaId, verifyState:'completed', validationCount: 5}
    )
      .then(() => {
        showNotification({
          title: "Idea marked verified successfuly",
          color: "teal",
          message: "",
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          title: "Error in Idea verification",
          color: "red",
          message: "please try again...",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200
        });
      });
  }

  const onClose = () => {
    setValidateModalOpened(false)
    updateValidation(
      auth0, 
      {ideaId, verifyState:'continue', validationCount: validationCount}
    ).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Modal
      opened={validateModalOpened}
      onClose={() => onClose()}
      withCloseButton={false}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      size={"992px"}
      padding={"0px"}
      sx={{
        ".mantine-Modal-content": {
          borderRadius: 8,
          overflow: "hidden"
        },
      }}
    >
      {loading && (
        <Overlay
          color="rgb(255 255 255 / 30%)" 
          zIndex={1000} 
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader size="xl" />
        </Overlay>
      )}
      {!isValidationDone && (
        <Flex w="100%" h="660px">
          <Flex w={"48%"} bg={"#5C5CEB"} p={"56px"}>
            <Transition
              mounted={!loading && !isValidationDone}
              transition="fade"
              duration={500}
              timingFunction="ease-in-out"
            >
              {(styles) => (
                <Flex
                  style={styles}
                  justify={"center"}
                  align={"center"}
                  direction={"column"}
                >
                  <Text
                    fz={20}
                    color="#ffffff"
                    fw={600}
                    lh={"29px"}
                    mb={"15px"}
                  >
                    {title}
                  </Text>
                  <Text
                    fz={18}
                    color="#ffffff"
                    fw={400}
                    mb={"50px"}
                    lh={"27px"}
                  >
                    {text}
                  </Text>
                  <CardIcon/>
                </Flex>
              )}
            </Transition>
          </Flex>
          <Box w={"52%"} bg={"white"}>
            <Transition
              mounted={!loading && !isValidationDone}
              transition="fade"
              duration={500}
              timingFunction="ease-in-out"
            >
              {(styles) => (
                <Box style={styles}>
                  <ValidationModalSideBar
                    reviewMode={reviewMode}
                    navToPerspective={navToPerspective}
                    setActiveSearch={setActiveSearch}
                    ideaPage
                    validateModalOpened={validateModalOpened}
                    setValidateModalOpened={setValidateModalOpened}
                    validationEvidence={validationEvidence}
                    loading={loading}
                    setLoading={setLoading}
                    setideaValidationData={setideaValidationData}
                    setMaturity={setMaturity}
                    handlebannerText={handlebannerText}
                    validationCount={validationCount}
                    setValidationCount={setValidationCount}
                    setIsValidationDone={setIsValidationDone}
                  />
                </Box>
              )}
            </Transition>
          </Box>
        </Flex>
      )}

      <Transition
        mounted={!loading && isValidationDone}
        transition="fade"
        duration={500}
        timingFunction="ease-in-out"
      >
        {(styles) => (
          <Flex justify={"center"} align={"center"} h={"660px"} style={styles}>
            <Box w={"338px"}>
              <IconCircleCheck fill="#0BAC4D" color="#ffffff" size={45}/>
              <Text fz={20} fw={600} mt={"5px"} ml={"8px"}>
                You are done!
              </Text>
              <Text fz={18} color="#585C68" my={"5px"} ml={"8px"}>
                You’re now set to receive the most relevant results
              </Text>
              <Button
                w={"70px"}
                fz={"12px"}
                fw={500}
                style={{ color: "#ffffff", backgroundColor: "#5C5CEB", height: "32px"}}
                ml={"8px"}
                p={"0px"}
                onClick={() => finishProcess()}
              >
                <IconCircleCheck fill="#ffffff" color="#5C5CEB" size={16}/>
                <Text ml={"5px"}>Finish</Text>
              </Button>
            </Box>
          </Flex>
        )}
      </Transition>
    </Modal>
  );
}

export default ValidateModal;
